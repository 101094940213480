@import "google-fonts";

// bootstrap & variables
@import "~bootstrap/scss/functions";
@import "variables";
@import "utilities";
@import "user";
@import "~bootstrap/scss/bootstrap";

// components
@import "components/_index";
