.iframe-privacy--wrapper {
   min-height: 200px;
   position: relative;
   max-width: 100%;
   width: 100%;

   iframe {
      background: #ececec;
   }
}

.map {
   background: #ececec;
}

.iframe-privacy--message {
   position: absolute;
   left: 50%;
   transform: translate(calc(-50% - 2rem));
   z-index: 1000;
   background: #fff;
   border-radius: 5px;
   max-width: 450px;
   width: 85%;
   margin: auto;
   padding: 20px 30px;
   margin: 2rem;
   text-align: center;

   p {
      font-size: 90%;
   }
}